//components/pagination.js
import React from "react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons"

export const Pagination = ({ totalCount }) => {
  const route = useLocation()
  console.log(route.pathname)

  const PerPage = 6
  const range = (start, end) =>
    [...Array(end - start + 1)].map((_, i) => start + i)

  return (
    <div className="md:mb-14 mb-8">
      <ul className="blog-pagination flex gap-3 font-mont mx-auto justify-center items-center">
        <li
          key="start"
          className="text-lightgrey border border-lightgrey bg-palegrey rounded flex justify-center items-center w-8 h-8 text-center"
        >
          <Link to={`/blog/page-1`} className="block w-8 h-8 leading-8">
            <FontAwesomeIcon icon={faChevronLeft} className="text-sm" />
          </Link>
        </li>
        {range(1, Math.ceil(totalCount / PerPage)).map((number, index) => (
          <li
            key={index}
            className={`${
              route.pathname.includes(number) ||
              (route.pathname === "/blog" && index === 0)
                ? `border-accent bg-accent text-white`
                : `border-lightgrey bg-palegrey text-lightgrey`
            } border rounded flex justify-center items-center w-8 h-8 text-center`}
          >
            <Link
              className={`${
                route.pathname.includes(number) ? `hover:text-white` : ``
              }block w-8 h-8 leading-8`}
              to={`/blog/page-${number}`}
            >
              {number}
            </Link>
          </li>
        ))}
        <li
          key="end"
          className="text-lightgrey border border-lightgrey bg-palegrey rounded flex justify-center items-center w-8 h-8 text-center"
        >
          <Link to={`/blog/page-${Math.ceil(totalCount / PerPage)}`} className="block w-8 h-8 leading-8">
            <FontAwesomeIcon icon={faChevronRight} className="text-sm" />
          </Link>
        </li>
      </ul>
    </div>
  )
}
